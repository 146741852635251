<ion-header>
  <ion-toolbar class="ps-4 pe-4"
    style="border-top-color: none; border-bottom-style: solid; border-bottom-color: whitesmoke; ">

    <figure>
      <a href="https://vehar-logistik.de/" target="_blank">
        <img src="assets/logo/logo_vehar_schriftzug.png" height="55" class="mt-3" />
      </a>
    </figure>
    <ion-buttons slot="end" style="margin-top: -10px !important;">
      <ion-button class="me-3 toolbar-button  d-none d-md-block book" translate routerLink="/dashboard/order">
        book_now
      </ion-button>
      <ion-button class="me-3 toolbar-button d-none d-md-block" href="https://vehar.zendesk.com/hc/de/categories/16707738279314-Kunden-Helpcenter " target="_blank">
        Service
      </ion-button>
      <ion-button class="me-3 toolbar-button" routerLink="/dashboard/profile/personal">
        <ion-icon class="big-icon" name="person-outline"></ion-icon>
      </ion-button>
      <ion-button id="language-center" class="toolbar-button">
        <img src="assets/countries/{{ currentLang }}.svg" height="20" width="30" style="margin: 0px" />
        <ion-icon name="caret-down-outline" class="caret ms-1"></ion-icon>
      </ion-button>
    </ion-buttons>


  </ion-toolbar>

  <ion-popover trigger="language-center" triggerAction="click">
    <ng-template>
      <ion-content>
        <p class="m-3" translate style="color: black;">language_select</p>
        <ion-list lines="none">
          <ion-item button (click)="setLang('de')">
            <img src="assets/countries/de.svg" height="20" width="30" style="margin: 0px" />
            <ion-label class="ms-3">Deutsch</ion-label>
            <ion-icon slot="end"
              [name]="currentLang === 'de' ? 'radio-button-on-outline' : 'radio-button-off-outline'"></ion-icon>
          </ion-item>
          <ion-item button (click)="setLang('en')">
            <img src="assets/countries/en.svg" height="20" width="30" style="margin: 0px" />
            <ion-label class="ms-3">English</ion-label>
            <ion-icon slot="end"
              [name]="currentLang === 'en' ? 'radio-button-on-outline' : 'radio-button-off-outline'"></ion-icon>
          </ion-item>
        </ion-list>
      </ion-content>
    </ng-template>
  </ion-popover>
</ion-header>
<div class="container-fluid pb-4" style="background-color: whitesmoke;">
  <div class="row" style="height: 100vh; overflow: hidden;" >

    <div class="col-12 col-lg-8 left-container" style="padding-bottom: 150px" #scrollView>
      <div class="card-container">
        <ion-card>
          <form [formGroup]="getFormGroup('address_preselect')">

            <div class="row p-4">
              <div class="col-12 col-lg-6 p-3">
                <div class="big-headline" translate>Abholort</div>
                <div class="mt-4 pe-2">
                  <mat-form-field appearance="outline" class="full-width" color="primary">
                    <mat-label translate>PLZ oder Ort</mat-label>
                    <input formControlName="from_address" (focus)="cities = []" [matAutocomplete]="auto" matInput
                      required />
                    <mat-icon matSuffix (click)="autofillCity('from_address')">my_location</mat-icon>
                  </mat-form-field>
                  <mat-autocomplete (optionSelected)="checkIfCityInGermany($event)" #auto="matAutocomplete"
                    [displayWith]="getCityName">
                    <mat-option *ngFor="let city of cities" [value]="city">
                      <img src="assets/countries/{{city.country_code?.toLowerCase()}}.svg" class="me-2" width="20" />
                      {{city.zipcode}} {{city.city}} <small>{{city.country_code}}</small>
                    </mat-option>
                  </mat-autocomplete>
                  <div class="mt-2">
                    <mat-form-field appearance="outline" class="full-width">
                      <mat-label translate>Straße + HNr</mat-label>
                      <input matInput type="text" formControlName="from_street" [autofocus]="true" />
                    </mat-form-field>

                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 p-3">
                <div class="big-headline" translate>Zustellort</div>
                <div class="mt-4 pe-2">
                  <mat-form-field appearance="outline" class="full-width" color="primary">
                    <mat-label translate>PLZ oder Ort</mat-label>
                    <input formControlName="to_address" (focus)="cities = []" [matAutocomplete]="auto" matInput
                      required />
                    <mat-icon matSuffix (click)="autofillCity('to_address')">my_location</mat-icon>
                  </mat-form-field>
                  <mat-autocomplete (optionSelected)="checkIfCityInGermany($event)" #auto="matAutocomplete"
                    [displayWith]="getCityName">
                    <mat-option *ngFor="let city of cities" [value]="city">
                      <img src="assets/countries/{{city.country_code?.toLowerCase()}}.svg" class="me-2" width="20" />
                      {{city.zipcode}} {{city.city}} <small>{{city.country_code}}</small>
                    </mat-option>
                  </mat-autocomplete>
                  <div class="mt-2">
                    <mat-form-field appearance="outline" class="full-width">
                      <mat-label translate>Straße + HNr</mat-label>
                      <input matInput type="text" formControlName="to_street" [autofocus]="true" />
                    </mat-form-field>

                  </div>
                </div>
              </div>
            </div>
          </form>
        </ion-card>
        <!-- Weitere Cards folgen -->
        <ion-card class="mt-4">
          <div class="row p-4">
            <div class="col-12">
              <div class="big-headline" translate>Was möchtest du versenden</div>
            </div>
            <div class="alert alert-warning mt-4" *ngIf="checkIfTooHeavy()">
              <b>
                <ion-icon name="warning-outline"></ion-icon>
                <span class="ms-2" translate [innerHTML]="'to_high_or_to_heavy_headline' | translate"></span>
              </b>
              <p translate [innerHTML]="'to_heavy_alert' | translate"></p>
            </div>
            <form [formGroup]="group.get('crates')" style="overflow-x:hidden;">
              <div formArrayName="crates" style="overflow-x: hidden;">
                <div class="row w-100 gx-2 mt-4 pt-2" *ngFor="let crates of getControls(); let i = index"
                  [formGroupName]="i" style="overflow-x: hidden;">
                  <div class="separator mt-2 mb-4">{{'Packstück' | translate}} #{{i+1}}</div>
                  <div class="col-xl-3 col-lg-4 col-md-4 col-6 mt-1" style="overflow-x: hidden;">
                    <mat-form-field appearance="outline" class="full-width pt-1" style="overflow-x: hidden;">
                      <mat-label translate>quantity</mat-label>
                      <input matInput type="number" formControlName="quantity" />
                      <span matTextSuffix translate [ngStyle]="{'color': isIframe ? '#bbb' : 'inherit'}">pcs</span>
                    </mat-form-field>
                  </div>
                  <div class="col-xl-3 col-lg-4 col-md-4 col-6 mb-1 mt-1" style="overflow-x: hidden;">
                    <mat-form-field appearance="outline" class="full-width pt-1">
                      <mat-label translate>packaging</mat-label>
                      <mat-select (valueChange)="updateLengthAndWidth($event, i)" formControlName="packaging"
                        [style.color.white]="isIframe">
                        <mat-option *ngFor="let option of options" [value]="option.name"><span
                            translate>{{option.name}}</span></mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-xl-3 col-lg-4 col-md-4 col-6 mb-1 mt-1" style="overflow-x: hidden;">
                    <mat-form-field appearance="outline" class="full-width pt-1">
                      <mat-label translate>length</mat-label>
                      <input matInput type="number" formControlName="length" />
                      <span matTextSuffix translate [ngStyle]="{'color': isIframe ? '#bbb' : 'inherit'}">cm</span>
                      
                      <mat-error  translate>
                        maximum_length_custom
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-xl-3 col-lg-4 col-md-4 col-6 mb-1 mt-1" style="overflow-x: hidden;">
                    <mat-form-field appearance="outline" class="full-width pt-1">
                      <mat-label translate>wide</mat-label>
                      <input matInput type="number" formControlName="wide" />
                      <span matTextSuffix translate [ngStyle]="{'color': isIframe ? '#bbb' : 'inherit'}">cm</span>
                      <mat-error translate>
                        maximum_wide_custom
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-xl-3 col-lg-4 col-md-4 col-6 mb-1 mt-1" style="overflow-x: hidden;">
                    <mat-form-field appearance="outline" class="full-width pt-1">
                      <mat-label translate>height</mat-label>
                      <input matInput type="number" formControlName="height" />

                      <span matTextSuffix translate [ngStyle]="{'color': isIframe ? '#bbb' : 'inherit'}">cm</span>
                      <mat-error translate>package_too_high</mat-error>

                    </mat-form-field>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-6 mb-1" style="overflow-x: hidden;">
                    <div class="row pt-1">
                      <div [ngClass]="getControls().length > 1 ? 'col-8' : 'col-12'">
                        <mat-form-field appearance="outline" class="full-width pt-1">
                          <mat-label translate>weight</mat-label>
                          <input matInput formControlName="weight" mask="separator" thousandSeparator="." />
                          <span matTextSuffix translate [ngStyle]="{'color': isIframe ? '#bbb' : 'inherit'}">kg</span>
                          <mat-error  translate
                            [translateParams]="{value: currentOption.maxWeight ?? 1000}">package_too_heavy</mat-error>
                          <mat-hint *ngIf="currentOption?.maxWeight">Max: {{ currentOption.maxWeight }}kg</mat-hint>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="col-12" style="overflow-x: hidden;">
                    <ion-item lines="none" style="--background: transparent;">
                      <ion-toggle color="light" slot="start" formControlName="stackable"></ion-toggle>
                      <ion-label>
                        <span translate [ngStyle]="{'color': isIframe ? '#000' : 'inherit'}">stackable_loadable</span>
                        <ion-icon
                          [matTooltip]="(individual ? 'stackable_tooltip_individual' : 'stackable_tooltip') | translate"
                          class="ms-2" name="information-circle-outline"
                          [ngStyle]="{'color': isIframe ? '#000' : 'inherit'}"></ion-icon>
                      </ion-label>
                    </ion-item>
                  </div>
                  <div class="col-12">
                    <div class="mt-4">
                      <ion-button (click)="removeOrClearCrates(i)">
                        <ion-label class="ms-2" translate>remove_package</ion-label>
                      </ion-button>
                    </div>
                  </div>
                </div>
                <hr>
                <ion-button (click)="addCrateGroup()" color="secondary" class="mt-4">
                  <ion-label class="ms-2" translate>add_package</ion-label>
                </ion-button>
              </div>
            </form>
            <div>
            </div>
          </div>
        </ion-card>
<mat-progress-bar mode="indeterminate" *ngIf="pricesLoading"></mat-progress-bar>
        <ion-card [hidden]="planPriceData[0] == 0" id="tarifArea">
          <form [formGroup]="group.get('loading_kind')">
            <div class="row p-4">
              <div class="col-12 col-lg-12 p-3">
                <div class="big-headline" translate>Den passenden Tarif wählen</div>
              </div>
              <div class="col-sm-12 col-md-6 col-xl-6 col-xxl-3 m-2">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label translate>loading_kind</mat-label>
                  <mat-select (selectionChange)="loadingKindChanged($event)" formControlName="loading_kind">
                    <mat-option *ngFor="let kind of loading_kinds" [value]="kind">{{kind | translate}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-sm-12 col-md-6 col-xl-6 col-xxl-3 m-2">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label translate>unloading_kind</mat-label>
                  <mat-select (selectionChange)="unloadingKindChanged($event)" formControlName="unloading_kind">
                    <mat-option *ngFor="let kind of unloading_kinds" [value]="kind">{{kind | translate}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

            </div>
            <div class="row g-1 overflow-hidden">
              <div class="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-12 mb-3" *ngIf="showClassic">

                <ion-card class="product-cards">
                  <ion-card-header>
                    <ion-card-title>
                      <span translate [innerHTML]="'shipment_method_standard' | translate"></span>

                      <hr />
                    </ion-card-title>
                  </ion-card-header>
                  <ion-card-content>
                    <ion-list>
                      <ion-item lines="none"
                        *ngFor="let item of ('standard_plan' | translate) | comparisationFormatter">
                        <ion-icon name="checkmark-outline"></ion-icon>
                        <ion-label class="ms-2" text-wrap>
                          <small>{{ item }}</small>
                        </ion-label>
                      </ion-item>
                      <ion-item lines="none">
                        <ion-label class="ms-2" text-wrap>
                          <small> <b>{{ 'pickup_from' | translate}}:
                            </b>{{this._date.getStartDateForPlan1(this.findFirstAvailableDate(0)!) | date:
                            'dd.MM.yyyy'}}</small>
                        </ion-label>
                      </ion-item>
                      <ion-item lines="none">

                        <ion-label class="ms-2" text-wrap>
                          <small> <b>{{ 'deliver_from_text' | translate}}:
                            </b>{{this._date.getStartDateForPlan1(this.findFirstAvailableDate(0)!) | date:
                            'dd.MM'}} - {{this._date.getEndDateForPlan1Date(this.findFirstAvailableDate(0)!) | date:
                            'dd.MM.yyyy'}}</small>
                        </ion-label>
                      </ion-item>
                    </ion-list>
                  </ion-card-content>

                  <div class="card-footer">
                    <div class="m-3">
                      <hr />

                      <b class="price" translate>price</b>
                      <h3 class="price">
                        <span *ngIf="!pricesLoading">
                          {{ planPriceData[0] ? planPriceData[0] + ' &euro;' : '-' }}
                        </span>
                        <span *ngIf="pricesLoading">
                          <ion-skeleton-text animated="true"
                            style="width: 100px; height: 20px; border-radius: 5px;"></ion-skeleton-text>
                        </span>

                      </h3>
                      <small translate>additional_tax</small>
                    </div>

                    <ion-button [color]="selectedPlan == 0 ? 'primary': 'secondary'" style="margin: 0px"
                      class="full-width" (click)="selectPlan(0)"
                      [disabled]="!plan1Possible || !this.getFormGroup('loading_kind').valid"
                      translate>choose</ion-button>
                  </div>
                </ion-card>

              </div>
              <div class=" col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-12 mb-3" *ngIf="showPoint">
                <ion-card class="product-cards">
                  <ion-card-header>
                    <ion-card-title>
                      <span [innerHTML]="'shipment_method_priority' | translate"></span>
                      <hr />
                    </ion-card-title>
                  </ion-card-header>
                  <ion-card-content>
                    <ion-list>
                      <ion-item lines="none" *ngFor="let item of ('express_plan' | translate) | comparisationFormatter">
                        <ion-icon name="checkmark-outline"></ion-icon>
                        <ion-label class="ms-2" text-wrap>
                          <small>{{ item }}</small>
                        </ion-label>
                      </ion-item>
                      <ion-item lines="none">

                        <ion-label class="ms-2" text-wrap>
                          <small><b>{{ 'pickup_from' | translate}}:</b>
                            {{this.plan2StartDate?.split('-')[0]?.trim()}}.{{this.plan2StartDate[this.plan2StartDate.length-4]}}{{this.plan2StartDate[this.plan2StartDate.length-3]}}{{this.plan2StartDate[this.plan2StartDate.length-2]}}{{this.plan2StartDate[this.plan2StartDate.length-1]}}</small>
                        </ion-label>
                      </ion-item>
                      <ion-item lines="none">

                        <ion-label class="ms-2" text-wrap>
                          <small> <b>{{ 'deliver_from_text' | translate}}: </b>{{this.plan2StartDate}}</small>
                        </ion-label>
                      </ion-item>
                    </ion-list>
                  </ion-card-content>

                  <div class="card-footer">
                    <div class="m-3">
                      <hr />

                      <b class="price" translate>price</b>
                      <h3 class="price">

                        <span *ngIf="!pricesLoading">
                          {{ planPriceData[1] ? planPriceData[1]+ ' &euro;' : '-' }}
                        </span>
                        <span *ngIf="pricesLoading">
                          <ion-skeleton-text animated="true"
                            style="width: 100px; height: 20px; border-radius: 5px;"></ion-skeleton-text>
                        </span>

                      </h3>
                      <small translate>additional_tax</small>
                    </div>

                    <ion-button [color]="selectedPlan == 1 ? 'primary': 'secondary'" style="margin: 0px"
                      class="full-width" (click)="selectPlan(1)"
                      [disabled]="!plan2Possible || !this.getFormGroup('loading_kind').valid "
                      translate>choose</ion-button>
                  </div>
                </ion-card>
              </div>
              <div class=" col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-12 mb-3" *ngIf="showDynamic">
                <ion-card class="product-cards">
                  <ion-card-header>
                    <ion-card-title>
                      <span [innerHTML]="'shipment_method_exact_delivery_date' | translate"></span>

                      <hr />
                    </ion-card-title>
                  </ion-card-header>
                  <ion-card-content>
                    <ion-list>
                      <ion-item lines="none"
                        *ngFor="let item of ('exact_delivery_plan' | translate) | comparisationFormatter">
                        <ion-icon name="checkmark-outline"></ion-icon>
                        <ion-label class="ms-2" text-wrap>
                          <small>{{ item }}</small>
                        </ion-label>
                      </ion-item>
                      <ion-item lines="none">

                        <ion-label class="ms-2" text-wrap>
                          <small><b>{{ 'pickup_from' | translate}}:</b>
                            {{plan3Range.split('-')[0].trim()}}.{{currentYear}}</small>
                        </ion-label>
                      </ion-item>

                      <ion-item lines="none">
                        <ion-label class="ms-2" text-wrap *ngIf="plan3Range.split('-').length==2">
                          <small> <b>{{ 'deliver_from_text' | translate}}:
                            </b> {{plan3Range.split('-')[1].trim()}}</small>
                        </ion-label>
                      </ion-item>
                    </ion-list>
                  </ion-card-content>

                  <div class="card-footer">
                    <div class="m-3">
                      <hr />

                      <b class="price" translate>price</b>
                      <h3 class="price">

                        <span *ngIf="!pricesLoading">
                          {{ planPriceData[2] ? planPriceData[2]+ ' &euro;' : '-' }}
                        </span>

                        <span *ngIf="pricesLoading">
                          <ion-skeleton-text animated="true"
                            style="width: 100px; height: 20px; border-radius: 5px;"></ion-skeleton-text>
                        </span>

                      </h3>
                      <small translate>additional_tax</small>
                    </div>

                    <ion-button [color]="selectedPlan == 2 ? 'primary': 'secondary'" style="margin: 0px"
                      class="full-width" (click)="selectPlan(2)"
                      [disabled]="!plan3Possible || !this.getFormGroup('loading_kind').valid"
                      translate>choose</ion-button>
                  </div>
                </ion-card>
              </div>
              <div class="col-xl-4 col-xxl-3 col-lg-4 col-md-6 col-12 mb-3" *ngIf="showHubflex">
                <ion-card class="product-cards">
                  <ion-card-header>
                    <ion-card-title>
                      <span [innerHTML]="'shipment_method_direct_drive' | translate"></span>

                      <hr />
                    </ion-card-title>
                  </ion-card-header>
                  <ion-card-content>
                    <ion-list>
                      <ion-item lines="none"
                        *ngFor="let item of ('direct_drive_plan' | translate) | comparisationFormatter">
                        <ion-icon name="checkmark-outline"></ion-icon>
                        <ion-label class="ms-2" text-wrap>
                          <small>{{ item }}</small>
                        </ion-label>
                      </ion-item>
                      <ion-item lines="none">

                        <ion-label class="ms-2" text-wrap>
                          <small> <b>{{ 'pickup_from' | translate}}:
                            </b>{{this._date.getStartDateForPlan1(this.findFirstAvailableDate(0)!) | date:
                            'dd.MM.yyyy'}}</small>
                        </ion-label>
                      </ion-item>
                      <ion-item lines="none">

                        <ion-label class="ms-2" text-wrap>
                          <small> <b>{{ 'deliver_from_text' | translate}}:
                            </b>{{this._date.getStartDateForPlan1(this.findFirstAvailableDate(0)!) | date:
                            'dd.MM'}} - {{this._date.getEndDateForPlan1Date(this.findFirstAvailableDate(0)!) | date:
                            'dd.MM.yyyy'}}</small>
                        </ion-label>
                      </ion-item>
                    </ion-list>
                  </ion-card-content>

                  <div class="card-footer">
                    <div class="m-3">
                      <hr />

                      <b class="price" translate>price</b>
                      <h3 class="price">
                        <span *ngIf="!pricesLoading">
                          {{ planPriceData[3] ? planPriceData[3] + ' &euro;' : '-' }}
                        </span>

                        <span *ngIf="pricesLoading">
                          <ion-skeleton-text animated="true"
                            style="width: 100px; height: 20px; border-radius: 5px;"></ion-skeleton-text>
                        </span>


                      </h3>
                      <small translate>additional_tax</small>
                    </div>

                    <ion-button [color]="selectedPlan == 3 ? 'primary': 'secondary'" style="margin: 0px"
                      class="full-width" (click)="selectPlan(3)"
                      [disabled]="!plan4Possible || !this.getFormGroup('loading_kind').valid "
                      translate>choose</ion-button>
                  </div>
                </ion-card>
              </div>
            </div>
          </form>
        </ion-card>
        <ion-card *ngIf="selectedPlan != null" id="timeFrame">
          <div class="row p-4">
            <div class="col-12">
              <div class="big-headline" translate>Dein Lieferzeitfenster wählen</div>
            </div>
          </div>
          <form [formGroup]="getFormGroup('additional_information')">
            <div class="row ps-4 pe-4">
              <div class="col-md-6 col-12 mt-4">
                <mat-form-field class="full-width mt-2" appearance="outline">
                  <mat-label translate>what_is_shipped</mat-label>
                  <mat-select formControlName="what_send">
                    <mat-option *ngFor="let option of sendOptions" [value]="option">{{option |
                      translate}}</mat-option>
                  </mat-select>
                </mat-form-field>

                <a translate href="https://veharlogistiko.de/a/help/article/56422" target="_blank"
                  style="color: rgb(71, 71, 71);">forbidden_goods</a>
              </div>
              <div class="col-12 mt-4">
                <b><span translate>collection</span></b>
              </div>
              <div class="col-6">
                <mat-form-field appearance="outline" class="full-width">
                  <input (dateChange)="updateEndDate($event)" style="display: none;" [matDatepickerFilter]="myFilter"
                    class="date-input" formControlName="start_date" readonly="true" [matDatepicker]="picker">
                  <input matInput [(ngModel)]="start_date" [ngModelOptions]="{standalone: true}" readonly="true"
                    (click)="picker.open()" />
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label translate>time</mat-label>
                  <mat-select formControlName="start_time">
                    <mat-option *ngFor="let slot of getTimeslots()" [value]="slot">{{slot}}</mat-option>
                  </mat-select>
                </mat-form-field>


              </div>
              <div class="col-12 mt-4">
                <b><span translate>delivery</span></b>
              </div>
              <div class="col-6">

                <mat-form-field appearance="outline" class="full-width">
                  <input formControlName="end_date" matInput readonly />
                  <mat-icon *ngIf="this.selectedPlan !== 1 && this.group.get('selectedTab')?.value !== 2" matSuffix
                    [matTooltip]="'end_date_tooltip'|translate">info</mat-icon>
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label translate>time</mat-label>
                  <mat-select formControlName="end_time">
                    <mat-option *ngFor="let slot of timeslots" [value]="slot">{{slot}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>


          </form>
        </ion-card>
        <ion-card class="mt-4 d-block d-lg-none " style="margin-bottom: 100px;">
          <div class="row p-4">
            <div class="col-12">
              <div class="big-headline" translate>Deine Sendung</div>
            </div>
          </div>
          <div class="row p-4">
            <div>
              <div class="detail-container">
                <div class="headline" translate>Von</div>
                <div *ngIf="getAddress('from_address')">{{getAddress('from_address')}}</div>
              </div>
              <div class="mt-3 detail-container">
                <div class="headline" translate>Nach</div>
                <div *ngIf="getAddress('to_address')">{{getAddress('to_address')}}</div>
              </div>
            </div>
            <hr class="mt-4">
            <div class="mt-3 detail-container">
              <div class="headline" translate>collection</div>
              <div>
                <div *ngIf="selectedPlan==0 || selectedPlan == 3"></div>
                <div *ngIf="selectedPlan == 1">
                  {{this.getFormGroup('additional_information').get('end_date')?.value}}
                </div>
                <div *ngIf="selectedPlan==2">
                  {{this.getFormGroup('additional_information').get('start_date')?.value | date:
                  "dd.MM.YYYY"}}
                </div>
              </div>
            </div>
            <div class="mt-3 detail-container">
              <div class="headline" translate>delivery</div>
              <div>
                {{this.getFormGroup('additional_information').get('end_date')?.value}}
              </div>
            </div>
            <hr class="mt-4">
            <div class="mt-3 detail-container">
              <div class="headline" translate>packages</div>
              <div>{{this.getFormGroup('crates').get('crates')?.length}}</div>
            </div>
            <div class="mt-3 detail-container">
              <div class="headline" translate>Ladefläche</div>
              <div>{{getLoadingMetreForCheckout().replace('.', ',')}}</div>
            </div>
            <div class="mt-3 detail-container">
              <div class="headline" translate>total_weight</div>
              <div>{{getTotalWeightForCheckout()}}</div>
            </div>
            <hr class="mt-4">
            <div class="mt-3 detail-container">
              <div class="headline" translate>net_amount</div>
              <div *ngIf="shippingPriceWithOutDC !== '0'"> {{shippingPriceWithOutDC}}
              </div>
            </div>
            <div class="mt-3 detail-container">
              <div class="headline" translate>vat</div>
              <div class="float-end" *ngIf="getMWST()">{{getMWST()}} €</div>
            </div>

            <hr>
            <div class="mt-3 detail-container">
              <div class="headline" translate>total</div>
              <div class="headline" *ngIf="total !== 0">{{getTotal()}} €</div>
            </div>
            <div class="mt-2 pb-4">
              <ion-button [disabled]="checkIfTooHeavy()" color="secondary" style="width: 100%;" (click)="storeAsSession()">
                <ion-label class="ms-2" translate>buy_now</ion-label>
              </ion-button>
            </div>
          </div>
        </ion-card>


      </div>
    </div>


    <div class="col-12 col-md-4 d-none d-lg-block price-container">
      <div class="price-area pt-3 pb-4">
        <h2 style="font-weight: 700;" class="mt-3" translate>Deine Sendung</h2>
        <div class="mt-4">
          <div class="mt-3 detail-container">
            <div class="headline" translate>Von</div>
            <div *ngIf="getAddress('from_address')">{{getAddress('from_address')}}</div>
          </div>
          <div class="mt-3 detail-container">
            <div class="headline" translate>Nach</div>
            <div *ngIf="getAddress('to_address')">{{getAddress('to_address')}}</div>
          </div>
        </div>
        <hr class="mt-4">
        <div class="mt-3 detail-container">
          <div class="headline" translate>collection</div>
          <div>
            <div *ngIf="selectedPlan==0 || selectedPlan == 3"></div>
            <div *ngIf="selectedPlan == 1">
              {{this.getFormGroup('additional_information').get('end_date')?.value}}
            </div>
            <div *ngIf="selectedPlan==2">
              {{this.getFormGroup('additional_information').get('start_date')?.value | date:
              "dd.MM.YYYY"}}
            </div>
          </div>
        </div>
        <div class="mt-3 detail-container">
          <div class="headline" translate>delivery</div>
          <div>
            {{this.getFormGroup('additional_information').get('end_date')?.value}}
          </div>
        </div>
        <hr class="mt-4">
        <div class="mt-3 detail-container">
          <div class="headline" translate>packages</div>
          <div>{{this.getFormGroup('crates').get('crates')?.length}}</div>
        </div>
        <div class="mt-3 detail-container">
          <div class="headline" translate>Ladefläche</div>
          <div>{{getLoadingMetreForCheckout().replace('.', ',')}}</div>
        </div>
        <div class="mt-3 detail-container">
          <div class="headline" translate>total_weight</div>
          <div>{{getTotalWeightForCheckout()}}</div>
        </div>
        <hr class="mt-4">
        <div class="mt-3 detail-container">
          <div class="headline" translate>netto_price</div>
          <div *ngIf="shippingPriceWithOutDC !== '0'"> {{shippingPriceWithOutDC}} €
          </div>
        </div>
        <div class="mt-3 detail-container">
          <div class="headline" translate>vat</div>
          <div class="float-end" *ngIf="getMWST()">{{getMWST()}} €</div>
        </div>

        <hr>
        <div class="mt-3 detail-container">
          <div class="headline" translate>brutto_price</div>
          <div class="headline" *ngIf="total !== 0">{{getTotal()}} €</div>
        </div>
        <div class="mt-4">
          <ion-button [disabled]="checkIfTooHeavy() || total !== 0 || this.group.invalid" color="secondary" style="width: 100%;" (click)="storeAsSession()">
            <ion-label class="ms-2" translate>start_auction</ion-label>
          </ion-button>

        </div>
      </div>
    </div>
  </div>
</div>

<ion-footer>
  <app-footer></app-footer>
</ion-footer>