<ion-toolbar>
  <ion-title translate>upload_documents</ion-title>
</ion-toolbar>
<div class="m-4">
  <form [formGroup]="group">
    <app-drop-zone-file-upload acceptFilter="application/pdf,image/png,image/jpg"
      (didUpload)="didUpload($event)"></app-drop-zone-file-upload>
  </form>
</div>
<div class="inner-content">

  <div class="clearfix w-100">
    <div class="float-end">
      <ion-button color="secondary" (click)="_modal.dismiss()" translate>cancel</ion-button>
      <ion-button color="primary" (click)="save()" [disabled]="group.invalid" translate>
        save
      </ion-button>
    </div>
  </div>
</div>