import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FileUploadEvent } from '../drop-zone-file-upload/drop-zone-file-upload.component';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-dialog-direct-upload-file',
  templateUrl: './dialog-direct-upload-file.component.html',
  styleUrls: ['./dialog-direct-upload-file.component.scss'],
})
export class DialogDirectUploadFileComponent {

  constructor(public _modal: ModalController
  ) { }

  group = new FormGroup({
    file: new FormControl('', [Validators.required]),
  });

  save() {
    this.group.disable();
    this._modal.dismiss(this.group.value);
  }

  didUpload(event: FileUploadEvent) {
    console.log(event);
    this.group.get('file')!.setValue(event.name);
  }
}
