<ion-card class="mt-4 mb-5 pt-4 pb-4">
  <ion-card-content class="text-center">
    <h2 style="font-size: 1.7rem" translate>Haben Sie Fragen?</h2>
    <div class="d-flex justify-content-center mt-3 flex-wrap">
      <ion-button href="mailto:support@vehar.de" size="small" color="primary" fill="outline" shape="round"
        size="default" class="me-2 mb-2">
        <ion-icon name="mail-open-outline" class="me-2"></ion-icon>
        <ion-label class="responsive-text">support@vehar.de</ion-label>
      </ion-button>
      <ion-button href="tel:+492087408820" color="secondary" shape="round" size="default" class="mb-2">
        <ion-icon name="call-outline" class="me-2"></ion-icon>
        <ion-label>+49 (0) 208 740 88 20</ion-label>
      </ion-button>
    </div>

    <div class="w-100">
      <ion-button (click)="openMail()" class="mt-3" color="dark" fill="outline" shape="round" size="default">
        <ion-icon name="chatbubble-ellipses-outline" class="me-2"></ion-icon>
        <ion-label>Vielversender?<br />Jetzt Kontakt aufnehmen!</ion-label>
      </ion-button>
    </div>
  </ion-card-content>
</ion-card>