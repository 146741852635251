

import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { FileUploadEvent } from 'src/app/components/drop-zone-file-upload/drop-zone-file-upload.component';
import { environment } from 'src/environments/environment';


@Component({
    selector: 'app-upload-files',
    templateUrl: './upload-files.component.html',
    styleUrls: ['./upload-files.component.scss'],
})
export class UploadFilesComponent implements OnInit {
    fileUrls: string[] = [];
    base64Data: any[] = [];

    @Output()
    goBack: EventEmitter<any> = new EventEmitter();

    @Input()
    insuranceId: string | undefined;

    @Output()
    filesUploaded: EventEmitter<string> = new EventEmitter();

    storeBase64($event: any) {
        this.base64Data.push($event);
    }
    storeUrl($event: FileUploadEvent) {
        if (!$event) return;
        this.fileUrls.push($event?.url);
    }

    constructor(private _loading: LoadingController, private _translate: TranslateService,
        private _http: HttpClient) { }

    ngOnInit() { }
    async sendFilesToInsurance() {
        const text = await this._translate.get('loading_controller_text').toPromise();
        const loading = await this._loading.create({
            message: text,
        });
        loading.present();
        this._http.post<any>(`${environment.api_url}/insurance/${this.insuranceId}/files`, {
            base64Data: this.base64Data,
            fileUrls: this.fileUrls
        }).subscribe(result => {
            loading.dismiss();
            this.filesUploaded.emit('');
        });
    }

    goBackToDetail() {
        this.goBack.emit();
    }
}
