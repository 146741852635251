<mat-tab-group (selectedTabChange)="renderMap($event)" *ngIf="data">
  <mat-tab *ngFor="let shipment of data.groups; let i = index">
    <ng-template mat-tab-label>
      {{i+1}}. <span translate>load_loadoff</span>
    </ng-template>


    <div class="row mt-2">
      <div class="col-6">
        <ion-card>
          <ion-card-content>
            <div class="heading" translate>request_type</div>
            <div class="content">{{ getType(shipment) | translate }}</div>
          </ion-card-content>
        </ion-card>

      </div>
      <div class="col-6">
        <ion-card>
          <ion-card-content>
            <div class="heading" translate>distance</div>
            <div class="content">{{shipment.distance }} Km</div>
          </ion-card-content>
        </ion-card>
      </div>
      <div class="col-12">
        <ion-card>
          <ion-card-content>
            <cookie-container cookieId="external" (didLoaded)="loadMap(0)">
              <div class="map" [id]="'map-'+i"></div>
            </cookie-container>
          </ion-card-content>
        </ion-card>
      </div>
      <div class="col-12 col-lg-6 mt-1">
        <ion-card>

          <ion-card-header>
            <ion-card-title translate>pickup</ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <p *ngIf="data.convertedToDirect == true && data.supplier == userId">{{ shipment.pickup.companyname }}</p>
            <p *ngIf="data.convertedToDirect == true && data.supplier == userId"> {{ shipment.pickup.contact }}</p>
            <p *ngIf="data.convertedToDirect == true && data.supplier == userId">{{ shipment.pickup.name }}</p>
            <p *ngIf="data.convertedToDirect == true && data.supplier == userId">{{ shipment.pickup.street }} {{
              shipment.pickup.housenumber }}</p>
            <p>{{ shipment.pickup.zipcode }}
              {{ shipment.pickup.city }} -
              {{ shipment.pickup.country.toUpperCase() }}
              <img class="ms-2 mb-1" src="assets/countries/{{ (shipment.pickup.country ?? '').toLowerCase() }}.svg"
                width="20" />
            </p>
            <p *ngIf="shipment.additional_information.pickup_start_date">
              <b translate>pickup_date</b>: <span>{{ shipment.additional_information.pickup_start_date | date:
                'dd.MM.yyyy'}} - {{
                shipment.additional_information.pickup_end_date | date:
                'dd.MM.yyyy'}} {{ shipment.additional_information.pickup_start_time }} - {{
                shipment.additional_information.pickup_end_time }} </span>
            </p>
            <p *ngIf="shipment.loading_kind">
              <b translate>loading_kind</b>: <span>{{shipment.loading_kind | translate }} </span>
            </p>
            <p *ngIf="shipment.pickup.reference  && data.convertedToDirect == true && data.supplier == userId">
              <b translate>pickup_reference</b>: <span>{{ shipment.pickup.reference}}</span>
            </p>
            <p *ngIf="shipment.pickup.contact  && data.convertedToDirect == true && data.supplier == userId">
              <b translate>contact</b>: <span>{{ shipment.pickup.contact}}</span>
            </p>
            <p *ngIf="shipment.pickup.email && data.convertedToDirect == true && data.supplier == userId">
              <b translate>email</b>: <a href="mailto:{{shipment.pickup.email}}">{{
                shipment.pickup.email
                }}</a>
            </p>
            <p *ngIf="shipment.pickup.phone  && data.convertedToDirect == true && data.supplier == userId">
              <b translate>phone</b>: <span>{{ shipment.pickup.phone}}</span>
            </p>
            <p *ngIf="shipment.pickup.notes">
              <b translate>additional_notes</b>: <br><span>{{ shipment.pickup.notes }}</span>
            </p>
          </ion-card-content>
        </ion-card>
      </div>
      <div class="col-12 col-lg-6 mt-1">
        <ion-card>
          <ion-card-header>
            <ion-card-title translate>delivery</ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <p *ngIf="data.convertedToDirect == true && data.supplier == userId">{{ shipment.deliver.companyname }}</p>
            <p *ngIf="data.convertedToDirect == true && data.supplier == userId">{{ shipment.deliver.contact }}</p>
            <p *ngIf="data.convertedToDirect == true && data.supplier == userId">{{ shipment.deliver.name }}</p>
            <p *ngIf="data.convertedToDirect == true && data.supplier == userId">{{ shipment.deliver.street }} {{
              shipment.deliver.housenumber }}</p>
            <p>{{ shipment.deliver.zipcode }}
              {{ shipment.deliver.city }} -
              {{ shipment.deliver.country.toUpperCase() }}
              <img class="ms-2 mb-1" src="assets/countries/{{ (shipment.deliver.country ?? '').toLowerCase() }}.svg"
                width="20" />
            </p>
            <p *ngIf="shipment.additional_information.deliver_start_date">
              <b translate>delivery_date</b>: <span>{{ shipment.additional_information.deliver_start_date | date:
                'dd.MM.yyyy'}}  - {{
                shipment.additional_information.deliver_end_date | date:
                'dd.MM.yyyy'}} - {{ shipment.additional_information.deliver_start_time }} {{ shipment.additional_information.deliver_end_time }} </span>
            </p>
            <p *ngIf="shipment.unloading_kind">
              <b translate>unloading_kind</b>: <span>{{shipment.unloading_kind | translate }} </span>
            </p>
            <p *ngIf="shipment.deliver.reference  && data.convertedToDirect == true && data.supplier == userId">
              <b translate>deliver_reference</b>: <span>{{ shipment.deliver.reference}}</span>
            </p>
            <p *ngIf="shipment.deliver.contact  && data.convertedToDirect == true && data.supplier == userId">
              <b translate>contact</b>: <span>{{ shipment.deliver.contact}}</span>
            </p>
            <p *ngIf="shipment.deliver.email  && data.convertedToDirect == true && data.supplier == userId">
              <b translate>email</b>: <a href="mailto:{{shipment.deliver.email}}">{{
                shipment.deliver.email }}</a>
            </p>
            <p *ngIf="shipment.deliver.phone  && data.convertedToDirect == true && data.supplier == userId">
              <b translate>phone</b>: <span>{{ shipment.deliver.phone}}</span>
            </p>
            <p *ngIf="shipment.deliver.notes ">
              <b translate>additional_notes</b>: <br><span>{{ shipment.deliver.notes }}</span>
            </p>
          </ion-card-content>
        </ion-card>
      </div>
      <div *ngIf="data.product == 'Vehar® Anonymous'">

        <ion-card *ngIf="existingOffer"
          [style.background]="(existingOffer.status === 'declined' ||existingOffer.status === 'negotiation_finally_declined_by_logistic' ) ? '#7017312b' : (existingOffer.status === 'accepted') ? '#42a94857' : (existingOffer.status === 'ask_for_negotiation' ? '#ffc40940' : 'white')">
          <ion-card-header>
            <ion-card-title translate>
              <div *ngIf="!existingOffer.status" translate>anonymous_offer_placed</div>
              <div *ngIf="existingOffer.status == 'declined'" translate>status_anonymous_declined</div>
              <div *ngIf="existingOffer.status == 'negotiation_finally_declined_by_logistic'" translate>
                negotiation_finally_declined_by_logistic</div>
              <div *ngIf="existingOffer.status == 'ask_for_negotiation'" translate>ask_for_negotiation</div>
              <div *ngIf="existingOffer.status == 'accepted'" translate>status_anonymous_accepted</div>
              <div *ngIf="existingOffer.status == 'negotiation_accepted'" translate>negotiation_accepted</div>
              <div *ngIf="existingOffer.status == 'offer_revoked'" translate>offer_revoked</div>
            </ion-card-title>
            <ion-card-subtitle><span translate>offer_date</span>
              <span> &nbsp; {{data.offer_date | date: 'dd.MM.yyyy'}}</span></ion-card-subtitle>
          </ion-card-header>
          <ion-card-content>
            <div class="container">
              <div class="row">
                <div class="col-12 pt-2 pb-2"
                  *ngIf="existingOffer.status == 'declined' || existingOffer.status == 'negotiation_finally_declined_by_logistic'"
                  align="center">
                  <div class="my-declined-offer">{{existingOffer.ekPrice.toLocaleString()}} &euro;</div>
                </div>

                <div class="col-12 pt-2 pb-2" *ngIf="existingOffer.status == 'accepted'" align="center">
                  <div class="my-accepted-offer">{{existingOffer.ekPrice.toLocaleString()}} &euro;</div>
                </div>
                <div class="col-12 pt-2 pb-2"
                  *ngIf="!existingOffer.status || existingOffer.status == 'negotiation_accepted'" align="center">
                  <div class="my-offer">{{existingOffer.ekPrice.toLocaleString()}} &euro;</div>
                </div>
                <div class="col-12 pt-2 pb-2" *ngIf="existingOffer.status == 'ask_for_negotiation'">
                  <div class="row">
                    <div class="col-lg-6 col-12">

                      <div translate>old_price</div>
                      <div class="my-offer breaktrough">{{existingOffer.oldPriceEk.toLocaleString()}} &euro;</div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <div translate>new_price</div>
                      <div class="my-offer">{{(existingOffer.ekPrice).toLocaleString()}} &euro;</div>
                    </div>
                    <div class="col-12" align="right">
                      <div class="w-100" align="right">
                        <ion-button color="danger" class="float-right" (click)="revokeOffer()">
                          <span translate>revoke_offer</span>
                          <ion-icon *ngIf="!isCancelLoading" slot="end" name="close-outline"></ion-icon>
                          <ion-spinner *ngIf="isCancelLoading" class="ms-1" name="crescent"></ion-spinner>
                        </ion-button>
                      </div>
                      <ion-button
                        *ngIf="(!existingOffer.count || existingOffer.count<= 2) && existingOffer.negotiatedByCustomer"
                        color="warning" class="float-right" (click)="negotiate()">
                        <span translate>status_anonymous_renegotiate</span>
                        <ion-icon slot="end" *ngIf="!loadingNegotiate" name="hand-left-outline" class="me-2"></ion-icon>
                        <ion-spinner *ngIf="loadingNegotiate" class="ms-1" name="crescent"></ion-spinner>
                      </ion-button>
                      <ion-button
                        *ngIf="existingOffer.count && existingOffer.count == 3 && existingOffer.negotiatedByCustomer"
                        color="secondary" class="float-right" (click)="declineNegotiation()">
                        <span translate>decline_negotiation</span>
                        <ion-icon *ngIf="!loadingNegotiate" slot="end" name="close-circle-outline"></ion-icon>
                        <ion-spinner *ngIf="loadingNegotiate" class="ms-1" name="crescent"></ion-spinner>
                      </ion-button>
                      <ion-button color="success" class="float-right" (click)="acceptOffer()"
                        *ngIf="existingOffer.negotiatedByCustomer">
                        <span translate>accept_offer</span>
                        <ion-icon *ngIf="!loading" slot="end" name="checkmark-circle-outline"></ion-icon>
                        <ion-spinner *ngIf="loading" class="ms-1" name="crescent"></ion-spinner>
                      </ion-button>

                    </div>
                  </div>
                </div>
                <div class="col-12 pt-2 pb-2"
                  *ngIf="existingOffer.status !== 'ask_for_negotiation' && existingOffer.status !== 'offer_revoked' && existingOffer.status !== 'accepted'">
                  <div class="w-100" align="right">
                    <ion-button color="danger" class="float-right" (click)="revokeOffer()">
                      <span translate>revoke_offer</span>

                      <ion-icon *ngIf="!loading" slot="end" name="close-outline"></ion-icon>
                      <ion-spinner *ngIf="loading" class="ms-1" name="crescent"></ion-spinner>
                    </ion-button>
                  </div>
                </div>
              </div>
            </div>
          </ion-card-content>
        </ion-card>

        <ion-card *ngIf="!existingOffer && !data.convertedToDirect">
          <ion-card-header>
            <ion-card-title translate>
              submit_request
            </ion-card-title>
            <ion-card-subtitle><span translate>offer_date</span>
              <span> &nbsp; {{data.offer_date | date: 'dd.MM.yyyy'}}</span></ion-card-subtitle>
          </ion-card-header>
          <ion-card-content>
            <div class="container">
              <div class="row">
                <div class="col-12 pt-4">
                  <mat-form-field appearance="outline" class="full-width">
                    <mat-label translate>net_amount</mat-label>
                    <input matInput [(ngModel)]="bet" mask="separator" thousandSeparator="." />
                    <span matTextSuffix>&euro;</span>
                  </mat-form-field>

                  <div class="w-100" align="right">
                    <ion-button color="secondary" class="float-right" [disabled]="!bet " (click)="placeOffer()">
                      <span translate>submit_request</span>
                      <ion-icon *ngIf="!loading" slot="end" name="checkmark-circle-outline"></ion-icon>
                      <ion-spinner *ngIf="loading" class="ms-1" name="crescent"></ion-spinner>
                    </ion-button>
                    <br>
                    <br>
                    <small translate>anonymous_place_order_disclaimer</small>
                  </div>
                </div>
              </div>
            </div>
          </ion-card-content>
        </ion-card>
      </div>
      <div
        *ngIf="data.product == 'Vehar® Auction' && (data.status == 'status_auction_open' || data.status == 'status_auction_offers')  && !data.status.includes('direct')">
        <ion-card>
          <ion-card-header>
            <ion-card-title translate>
              place_bet
            </ion-card-title>
            <ion-card-subtitle>
              {{'last_bet' | translate}} {{bestBet ?? 'Kein Gebot vorhanden'}} <span *ngIf="bestBet">&euro;</span>
            </ion-card-subtitle>
          </ion-card-header>
          <ion-card-content>
            <div class="container">
              <div class="row">
                <div class="col-12 col-lg-6 pt-lg-3 pt-2">
                  <div class="countdown">
                    {{this.timer}}
                  </div>
                </div>
                <div class="col-12 col-lg-6 pt-lg-1 pt-4">
                  <mat-form-field appearance="outline" class="full-width">
                    <mat-label translate>net_amount</mat-label>
                    <input matInput [(ngModel)]="bet" mask="separator" thousandSeparator="." />
                    <span matTextSuffix>&euro;</span>
                  </mat-form-field>


                  <div class="w-100" align="right">
                    <ion-button color="secondary" class="float-right" *ngIf="!data.maximumbet"
                      [disabled]="!bet ||oldbet<=bet ||   bestBet!<=bet" (click)="placeBet()">
                      <span translate>place_bet</span>
                      <ion-icon *ngIf="!loading" slot="end" name="checkmark-circle-outline"></ion-icon>
                      <ion-spinner *ngIf="loading" class="ms-1" name="crescent"></ion-spinner>
                    </ion-button>
                    <ion-button color="secondary" class="float-right" *ngIf="data.maximumbet"
                      [disabled]="!bet || bet>=this.data?.maximumbet || oldbet<=bet || bestBet!<=bet"
                      (click)="placeBet()">
                      <span translate>place_bet</span>
                      <ion-icon *ngIf="!loading" slot="end" name="checkmark-circle-outline"></ion-icon>
                      <ion-spinner *ngIf="loading" class="ms-1" name="crescent"></ion-spinner>
                    </ion-button>
                    <br>
                    <br>
                    <small translate>info_text_bet</small>
                    <hr *ngIf="data.maximumbet && data.maximumbet != ''" >
                    <ion-button color="primary" class="w-100 mt-2" *ngIf="data.maximumbet && data.maximumbet != ''"(click)="buyBet()">
                      <span translate>auction_buy_now &nbsp;</span>
                      <span>{{buyNowPrice | async}} &euro;</span>
                      <ion-spinner *ngIf="loadingBuyNow" class="ms-1" name="crescent"></ion-spinner>
                    </ion-button>
                  </div>

                  <hr *ngIf="this.oldbet">
                  <div class="row" *ngIf="this.oldbet">
                    <div class="col"><span translate>my_current_bet &nbsp;</span> <span
                        class="my-bet">{{this.oldbet.toFixed(2).replace('.',',')}}
                        &euro;</span></div>
                    <div class="col" align="right">
                      <a class="anchor" (click)="deleteBet()" translate>delete_bet_anchor</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ion-card-content>
        </ion-card>
      </div>
      <div class="col-12 mt-2">
        <ion-card>
          <ion-card-header>
            <ion-card-title translate>
              packaging
            </ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <div class="mt-2">
              <div *ngIf="shipment.selectedTab == 0">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col" translate>quantity</th>
                      <th scope="col" translate>packaging</th>
                      <th scope="col" translate>dimension</th>
                      <th scope="col" translate>weight</th>
                      <th scope="col" translate>stackable_loadable</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let crate of shipment.crates.crates">
                      <th scope="row">{{ crate?.quantity }}</th>
                      <td>{{ crate?.packaging | translate }}</td>
                      <td>({{ crate?.length }}x{{ crate?.wide }}x{{
                        crate?.height }}cm)</td>
                      <td>{{ crate?.weight }} kg</td>
                      <td *ngIf="crate?.stackable" translate>yes</td>
                      <td *ngIf="!crate?.stackable" translate>no</td>
                    </tr>

                  </tbody>
                </table>
              </div>
              <div *ngIf="shipment.selectedTab == 1">
                <table class="table">
                  <tbody>
                    <tr>
                      <th translate>loading_meter</th>
                      <td>{{ shipment.loading_meter.loading_meter.toLocaleString() }} ldm</td>
                    </tr>
                    <tr>
                      <th translate>total_weight</th>
                      <td> {{ shipment.loading_meter.weight.toLocaleString() }} kg</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div *ngIf="shipment.selectedTab == 2">
                <table class="table">
                  <tbody>
                    <tr>
                      <th translate>request_type</th>
                      <td translate>
                        request_based_vehicle_type
                      </td>
                    </tr>
                    <tr>
                      <th translate>vehicle_type</th>
                      <td> <span *ngIf="shipment.vehicles.vehicleType == 0" translate>sprinter_image</span>
                        <span *ngIf="shipment.vehicles.vehicleType == 1" translate>truck_75</span>
                        <span *ngIf="shipment.vehicles.vehicleType == 2" translate>truck_12</span>
                        <span *ngIf="shipment.vehicles.vehicleType == 3" translate>big_truck</span>
                        <span *ngIf="shipment.vehicles.vehicleType == 4" translate>big_truck_mega</span>
                        <span *ngIf="shipment.vehicles.vehicleType == 5" translate>truck_thermo</span>
                        <span *ngIf="shipment.vehicles.vehicleType == 6" translate>truck_crate</span>
                        <span *ngIf="shipment.vehicles.vehicleType == 7" translate>truck_coil</span>
                        <span *ngIf="shipment.vehicles.vehicleType == 8" translate>truck_glider</span>
                        <span *ngIf="shipment.vehicles.vehicleType == 9" translate>open_truck</span>
                        <span *ngIf="shipment.vehicles.vehicleType == 10" translate>low_level_truck</span>
                      </td>
                    </tr>
                    <tr>
                      <th translate>total_weight</th>
                      <td>
                        {{ shipment.vehicles.totalWeight }} kg
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </ion-card-content>
        </ion-card>
      </div>
      <div class="col-12 mt-2">
        <ion-card>
          <ion-card-header>
            <ion-card-title translate>
              additional_options
            </ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <p class="mt-2">
              <b translate>what_is_shipped</b>: <span>{{ shipment.additional_information.what_send |
                translate }}</span>
            </p>

            <p class="mt-2" *ngIf="shipment.additional_information.additional_notes">
              <b translate>additional_notes_anonymous</b>: <br><span>{{
                shipment.additional_information.additional_notes }}</span>
            </p>
            <table class="table">
              <tbody>
                <tr>
                  <th translate>pickup_notification</th>
                  <td>
                    <span>{{includesOption(shipment.additional_information.additional_options, 'pickup_notification')
                      ? 'erforderlich' : 'nicht erforderlich' }}</span>
                  </td>
                </tr>
                <tr>
                  <th translate>delivery_notification</th>
                  <td>
                    <span>{{includesOption(shipment.additional_information.additional_options, 'delivery_notification')
                      ? 'erforderlich' : 'nicht erforderlich' }}</span>
                  </td>
                </tr>

                <tr>
                  <th translate>time_frame_booking</th>
                  <td>
                    <span>{{includesOption(shipment.additional_information.additional_options, 'timeframe')
                      ? 'erforderlich' : 'nicht erforderlich' }}</span>
                  </td>
                </tr>
                <tr>
                  <th translate>transportinsurance</th>
                  <td>
                    <span>{{includesOption(shipment.additional_information.additional_options, 'transportinsurance')
                      ? 'erforderlich' : 'nicht erforderlich' }}</span>
                  </td>
                </tr>
                <tr>
                  <th translate>dangerousgoods</th>
                  <td>
                    <span>{{includesOption(shipment.additional_information.additional_options, 'dangerousgoods')
                      ? 'vorhanden' : 'kein Gefahrgut' }}</span>
                    <ul class="ms-2"
                      *ngIf="includesOption(shipment.additional_information.additional_options, 'dangerousgoods')">
                      <li>
                        <span><span translate>dangerous_goods_class</span>: {{
                          getValueForAdditionalInformation(shipment.additional_information.additional_options,
                          'dangerousgoods').dangerous_goods_class
                          }}</span>
                      </li>
                      <li>
                        <span><span translate>packaging_group</span>: {{
                          getValueForAdditionalInformation(shipment.additional_information.additional_options,
                          'dangerousgoods').packaging_group
                          }}</span>
                      </li>
                      <li>
                        <span><span translate>packaging_group</span>: {{
                          getValueForAdditionalInformation(shipment.additional_information.additional_options,
                          'dangerousgoods').un_number
                          }}</span>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th translate>pallet_exchange</th>
                  <td>
                    <span>{{includesOption(shipment.additional_information.additional_options, 'pallet_exchange')
                      ? 'erforderlich' : 'nicht erforderlich' }}</span>
                    <ul class="ms-2"
                      *ngIf="includesOption(shipment.additional_information.additional_options, 'pallet_exchange')">
                      <li>
                        <span><span translate>quantity</span>: {{
                          getValueForAdditionalInformation(shipment.additional_information.additional_options,
                          'pallet_exchange').qty
                          }}</span>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </ion-card-content>
        </ion-card>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>