<div class="position-relative main-container">
  <div class="p-4">
    <div class="container mt-4">
      <div class="row">
        <div class="col-12 col-lg-4">
          <div class="item">
            <div class="heading" *ngIf="data.task_nr" translate>task_nr</div>
            <div class="heading" *ngIf="!data.task_nr && data.product !== 'Vehar® Auction'" translate>offer_number</div>
            <div class="heading" *ngIf="data.product == 'Vehar® Auction'" translate>auction_number</div>
            <div class="content" *ngIf="!data.task_nr">{{ data.offer_number }}</div>
            <div class="content" *ngIf="data.task_nr">{{ data.task_nr }}</div>
          </div>
        </div>
        <div class="col-12 col-lg-3 mb-3">
          <div class="item">
            <div class="heading" translate>status</div>
            <div class="content">{{ data.status | translate}}</div>
          </div>
        </div>
        <div class="col-12 col-lg-5" *ngIf="data.convertedToDirect">
          <div class="item">
            <div class="d-flex align-items-center">
              <div class="heading" translate>documents</div>
              <button mat-icon-button (click)="uploadDocuments()" [matTooltip]="'upload_documents' | translate"
                class="ms-2">
                <mat-icon>attach_file</mat-icon>
              </button>
            </div>
            <b translate *ngIf="!(data.attachments?.length > 0)">no_documents_available</b>
            <ul>
              <li *ngFor="let attachment of data.attachments">
                <span translate>{{attachment.type}}</span> ({{ dayjs(attachment.uploaded_at).format('DD.MM.YYYY HH:mm')
                }})
                <a [href]="attachment.url" target="_blank" class="ms-2">
                  <ion-icon name="cloud-download"></ion-icon>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-lg-4 mt-3" *ngIf="mail">
          <div class="item">
            <div class="heading" translate>offer_detail_contact_us</div>
            <div class="content">
              <a [href]="'mailto:'+this.mail+'?subject='+getOfferNr()">{{this.mail}}</a>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 mt-3" *ngIf="data.convertedToDirect">
          <div class="heading" translate>payment_method</div>
          <div class="content"
            *ngIf="!data.payment_type || data.payment_type == '-' || data.payment_type == 'no_paying_action_taken'">
            <a (click)="payNow()" translate>pay_now_detail</a>
          </div>

          <div class="content" *ngIf="data.payment_type && data.payment_type !== 'no_paying_action_taken'">
            {{data.payment_type | translate}}
            <span
              *ngIf="!data.payment_processed && data.payment_type !== 'stripe_progress' && data.payment_type !== '-'">
              · wird verarbeitet</span>
          </div>
        </div>

        <div class="col-12 mt-3" *ngIf="data.total">
          <div class="item">
            <div class="heading" translate>freight_price</div>
            <div class="content">{{ data.total?.toFixed(2) }} &euro;</div>
          </div>
        </div>
        <div class="col-12 col-lg-4 mt-3">
          <div class="item">
            <div class="heading" translate>reclamations</div>
            <div class="content">
              <a translate (click)="openComplaints()">create_reclamation_now</a>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 mt-3" *ngIf="data.convertedToDirect && !data.requestedDeliveryDocs">
          <div class="item">
            <div class="heading" translate>delivery_documents</div>
            <div class="content" *ngIf="!data.requestedDeliveryDocuments">
              <a (click)="requestDeliveryDocuments()" translate>request_delivery_document</a>
            </div>
          </div>
        </div>
        <div class="col-12 mt-4">

          <app-anonymous-auction-content *ngIf="data.product !== 'Vehar® Direct'"
            (reloadAnonymousOrder)="reloadAnonymousOrder()" [data]="this.data"></app-anonymous-auction-content>
          <app-direct-content *ngIf="data.product == 'Vehar® Direct'" [data]="this.data"></app-direct-content>
          <div class="col-12 mt-2"
            *ngIf="(data.shippingPrice && data.product == 'Vehar® Direct') || (data.price && data.product !== 'Vehar® Direct')">
            <ion-card>
              <ion-card-header>
                <ion-card-title translate>
                  price
                </ion-card-title>
              </ion-card-header>
              <ion-card-content>
                <div class="container">
                  <div class="row">

                    <div class="col-12 pt-2 pb-2" align="left">

                      <div class="items" align="right">
                        <div class="row" style="max-width: 400px;">
                          <div class="col-6 costs" translate>
                            total_net
                          </div>
                          <div class="col-6 costs" align="right">
                            <span *ngIf="data.product == 'Vehar® Direct'">
                              {{(data.shippingPrice ).toFixed(2).replace('.', ',')}} &euro;
                            </span>
                            <span *ngIf="data.product !== 'Vehar® Direct'">
                              {{(data.price ).toFixed(2).replace('.', ',')}} &euro;
                            </span>

                          </div>
                        </div>
                      </div>

                      <div class="items" align="right">
                        <div class="row" style="max-width: 400px;">
                          <div class="col-6 costs" translate>
                            totalAdditionalCosts
                          </div>
                          <div class="col-6 costs" align="right">
                            {{(data.totalAdditionalCosts).toFixed(2).replace('.', ',')}} &euro;
                          </div>
                        </div>
                      </div>
                      <hr>

                      <div align="right">
                        <div class="row" style="max-width: 400px;">
                          <div class="col-6 net_price" translate>
                            netto_price
                          </div>
                          <div class="col-6 net_price" align="right">
                            <span *ngIf="data.product == 'Vehar® Direct'">
                              {{(data.shippingPrice +data.totalAdditionalCosts).toFixed(2).replace('.', ',')}} &euro;
                            </span>
                            <span *ngIf="data.product !== 'Vehar® Direct'">
                              {{(data.price +data.totalAdditionalCosts).toFixed(2).replace('.', ',')}} &euro;
                            </span>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ion-card-content>
            </ion-card>
          </div>
        </div>
      </div>
    </div>
    <mat-icon class="close-icon" (click)="close()">close</mat-icon>
  </div>
</div>