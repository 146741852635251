import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Preferences } from '@capacitor/preferences';
import { LoadingController, NavController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime } from 'rxjs/operators';
import { AddressService } from 'src/app/services/address-service/address-service.service';
import { UserService } from 'src/app/services/user/user.service';
import { CountryNotAllowedComponent } from '../../dashboard/order/direct/country-not-allowed/country-not-allowed.component';
import { PriceSettings } from 'src/app/types/settings';
import { City } from 'src/app/types/address';
import { ToMuchLdmOrToHeavyModalComponent } from '../../dashboard/order/direct/crate-input/to-much-ldm-or-to-heavy-modal/to-much-ldm-or-to-heavy-modal.component';
import { DateService } from 'src/app/services/date/date.service';
import dayjs from 'dayjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DateFilterFn } from '@angular/material/datepicker';

@Component({
  selector: 'app-enter-order-infos',
  templateUrl: './enter-order-infos.component.html',
  styleUrls: ['./enter-order-infos.component.scss'],
})
export class EnterOrderInfosComponent implements OnInit, AfterViewInit {
  shippingPriceWithOutDC: string = '0'
  currentLang = 'de';
  showOverSizeAlert = false;
  total = 0;
  distance = 0;
  showClassic = true;
  showPoint = true;
  selectedPlan: any = null;
  showHubflex = true;
  start_date = '';
  showDynamic = true;
  pricesLoading = false;;
  planPriceData: any = [
    0,
    0,
    0,
    0,
    0
  ]

  createNewCrateFormGroup(individual: boolean): FormGroup {
    return new FormGroup({
      'quantity': new FormControl('1', Validators.required),
      'packaging': new FormControl(null, Validators.required),
      'length': new FormControl({ value: '120', disabled: individual ? false : true }, [Validators.required, Validators.min(0.1), Validators.max(240)]),
      'wide': new FormControl({ value: '80', disabled: individual ? false : true }, [Validators.required, Validators.min(0.1), Validators.max(120)]),
      'height': new FormControl('100', individual ? [Validators.required] : [Validators.required, Validators.min(0.1), Validators.max(170)]),
      'weight': new FormControl('300', individual ? [Validators.required] : [Validators.required, Validators.min(1), Validators.max(1000)]),
      'stackable': new FormControl(null)
    });
  }

  currentYear = dayjs().year();
  loading_kinds = ['loading_kind_sender', 'loading_kind_side', 'loading_kind_top', 'loading_by_sender'];
  unloading_kinds = ['unloading_kind_sender', 'unloading_kind_top', 'unloading_lift', 'unloading_kind_side'];
  cars = ['sprinter', 'with_edscha']
  timeslots = ['07:00 - 16:00', '08:00 - 17:00', '06:00 - 14:00', '09:00 - 15:00', '11:00 - 17:30']
  sendOptions = ["industrial_goods", "consum_goods", "print_goods", "machines", "land_and_forest", "car_goods", "building_goods", "additional_goods", "tobacco_over_200k", "mobiles_over_200k", "edv_over_200k", "communication_over_200k", "computer_over_200k", "peripherals_over_200k", "chips_over_200k", "liqour_over_200k", "jewelry", "docs", "drugs", "real_pearls", "metals", "stoans", "stuff_from_stoans_and_metals", "money", "juwels", "credits"]

  signed_in_mail = ''
  individual = false;
  cities: City[] = [];
  isIframe = false;
  allowedCountries: string[] = [];
  isLoading = false;
  options = [
    {
      name: 'custom_crate',
      length: 110,
      width: 80
    },
    {
      name: 'euro_pallet_80',
      length: 120,
      width: 80
    },
    {
      name: 'euro_pallet_100',
      length: 120,
      width: 100
    },
    {
      name: 'one_pallet_80',
      length: 120,
      width: 80
    },
    {
      name: 'one_pallet_120',
      length: 120,
      width: 120
    },
    {
      name: 'one_pallet_100',
      length: 120,
      width: 100
    },
    {
      name: 'chep_euro_pallet',
      length: 120,
      width: 80
    },
    {
      name: 'chep_part_pallet',
      length: 76,
      width: 51
    },
    {
      name: 'chep_quarter_pallet',
      length: 60,
      width: 40
    },
    {
      name: 'euro_grid_box',
      length: 124,
      width: 84
    },
    {
      name: 'dus_pallet',
      length: 80,
      width: 60
    },
    {
      name: 'cp1',
      length: 120,
      width: 100
    },
    {
      name: 'cp2',
      length: 120,
      width: 80
    },
    {
      name: 'cp3',
      length: 114,
      width: 114
    },
    {
      name: 'cp4',
      length: 130,
      width: 110
    },
    {
      name: 'cp5',
      length: 114,
      width: 76
    },
    {
      name: 'cp6',
      length: 120,
      width: 100
    },
    {
      name: 'cp7',
      length: 130,
      width: 110
    },
    {
      name: 'cp8',
      length: 114,
      width: 114
    },
    {
      name: 'cp9',
      length: 114,
      width: 114
    },
    {
      name: 'EH1',
      length: 120,
      width: 80
    },
    {
      name: 'ibc_case',
      length: 120,
      width: 110
    },
    {
      name: 'xl_package',
      length: 80,
      width: 40,
      height: 30,
      maxWeight: 40
    },
    {
      name: 'l_package',
      length: 60,
      width: 38,
      height: 25,
      maxWeight: 40
    },
    {
      name: 'm_package',
      length: 45,
      width: 30,
      height: 20,
      maxWeight: 40
    },
    {
      name: 's_package',
      length: 35,
      width: 20,
      height: 10,
      maxWeight: 40
    },
    {
      name: 'xs_package',
      length: 28,
      width: 20,
      height: 5,
      maxWeight: 40
    }

  ]
  plan2EndDateRange = '';
  plan2StartDate = '';
  updatePickupType = true;
  plan1Possible = false;
  plan2Possible = false;
  plan3Possible = false;
  plan4Possible = false;
  plan3Range = '';
  currentOption: any = this.options[0];
  package_data = {
    packages: 1,
    loading_meter: 0.4,
    total_weight: 300
  };
  constructor(
    private _translate: TranslateService,
    public _user: UserService,
    public _date: DateService,
    private _ref: ChangeDetectorRef,
    private _builder: FormBuilder,
    private dialog: MatDialog,
    private _address: AddressService,
    private _store: AngularFirestore,
    private nav: NavController,
    private _http: HttpClient,
    private _loading: LoadingController,
    private _toast: ToastController
  ) { }

  group = new FormGroup({
    address_preselect: this._builder.group({
      from_address: ['', [Validators.required, Validators.minLength(10)]],
      from_street: ['', [Validators.required]],
      to_address: ['', [Validators.required, Validators.minLength(10)]],
      to_street: ['', [Validators.required]],
    }),
    crates: this._builder.group({
      crates: this._builder.array([]),
      loading_meter: [0.4, []],
      weight: [300,]
    }),
    loading_kind: this._builder.group({
      unloading_kind: ['', [Validators.required]],
      loading_kind: ['', [Validators.required,]],
      car: ['sprinter', [Validators.required]]
    }),
    additional_information: this._builder.group({
      delivery_kind: ['b2b'],
      start_date: ['', [Validators.required]],
      end_date: [{ value: '', disabled: true }, [Validators.required]],
      start_time: ['', [Validators.required]],
      pickup_end_date: [,],
      pickup_start_date: [,],
      deliver_end_date: [,],
      deliver_start_date: [],
      end_time: ['', [Validators.required]],
      what_send: ['', Validators.required],
      internal_reference: [''],
      additional_options: [[]],
    }),
  })

  loadingKindChanged($event: any) {
    if ($event.value == 'loading_top' || (this.group.get('loading_kind')!.get('unloading_kind')!.value && this.group.get('loading')?.get('unloading_kind')!.value == 'unloading_top')) {

      this.group.get('loading_kind')!.get('car')!.setValue('with_edscha')
    } else {
      this.group.get('loading_kind')!.get('car')!.setValue('sprinter')
    }
    if (($event.value == 'loading_kind_side' || $event.value == 'loading_kind_top') && this.group.get('loading_kind')!.get('unloading_kind')!.value) {
      this.plan4Possible = true;
      this.plan1Possible = false;
      this.plan2Possible = false;
      this.plan3Possible = false;
    } else {
      if (this.group.get('loading_kind')!.get('unloading_kind')!.value && (this.group.get('loading_kind')!.get('unloading_kind')!.value == 'unloading_kind_top' || this.group.get('loading_kind')!.get('unloading_kind')!.value == 'unloading_lift')) {
        this.plan4Possible = true;
        this.plan1Possible = false;
        this.plan2Possible = false;
        this.plan3Possible = false;
      } else {
        this.plan4Possible = false;
        this.plan1Possible = true;
        this.plan2Possible = true;
        this.plan3Possible = true;
      }
    }
  }

  unloadingKindChanged($event: any) {

    if ($event.value == 'unloading_top' || (this.group.get('loading_kind')!.get('loading_kind')!.value && this.group.get('loading_kind')!.get('loading_kind')!.value == 'loading_top')) {


      this.group.get('loading_kind')!.get('car')!.setValue('with_edscha')
    } else {
      console.log("hier rein");

      this.group.get('loading_kind')!.get('car')!.setValue('sprinter')
    }
    if (($event.value == 'unloading_kind_top' || $event.value == 'unloading_lift') && this.group.get('loading_kind')!.get('loading_kind')!.value) {
      this.plan4Possible = true;
      this.plan1Possible = false;
      this.plan2Possible = false;
      this.plan3Possible = false;
    } else {
      if (this.group.get('loading_kind')!.get('loading_kind')!.value) {
        if (this.group.get('loading_kind')!.get('loading_kind')!.value == 'loading_kind_side' || this.group.get('loading_kind')!.get('loading_kind')!.value == 'loading_kind_top') {
          this.plan4Possible = true;
          this.plan1Possible = false;
          this.plan2Possible = false;
          this.plan3Possible = false;
        } else {
          this.plan4Possible = false;
          this.plan1Possible = true;
          this.plan2Possible = true;
          this.plan3Possible = true;
        }
      } else {
        this.plan1Possible = false;
        this.plan2Possible = false;
        this.plan3Possible = false;
        this.plan4Possible = false;
      }
    }
  }

  getControls() {
    return (this.group.get('crates')!.get('crates') as FormArray).controls;
  }

  public removeOrClearCrates(i: any = null) {
    const crates = this.group.get('crates')!.get('crates') as FormArray;
    if (crates.length > 1) {
      if (i) {
        crates.removeAt(i);
        return;
      }
      crates.removeAt(crates.length - 1);
    } else {
      crates.reset();
    }
  }

  updateLengthAndWidth($event: any, index: number) {
    const value = $event;
    const selectedOption = this.options.filter((option: any) => option.name === value);
    this.currentOption = selectedOption[0];
    const fg: any = (this.group.get('crates')!.get('crates') as FormArray).at(index) as FormGroup;
    if (this.currentOption.name == 'custom_crate') {
      fg.enable()
      fg.controls.weight.clearValidators()
      fg.controls.weight.setValidators([Validators.required, Validators.min(1)])
      if (true) {
        fg.controls.length.setValidators([Validators.required, Validators.max(240)]);
        fg.controls.wide.setValidators([Validators.required, Validators.max(120)]);
      }
    } else {
      fg.controls.weight.setValidators([Validators.required, Validators.min(1), Validators.max(selectedOption[0].maxWeight ?? 1000)])
      fg.controls.weight.setValidators([Validators.required, Validators.min(1), Validators.max(1000)])
      fg.controls.length.setValue(selectedOption[0].length);
      fg.controls.wide.setValue(selectedOption[0].width);
      fg.controls.length.disable()
      fg.controls.wide.disable()
    }
    fg.controls.weight.updateValueAndValidity()

    const heightControl = fg.controls.height;
    if (selectedOption[0].height) {
      heightControl.setValue(selectedOption[0].height);
      heightControl.disable();
    } else heightControl.enable();

    const weightControl = fg.controls.weight;
    if (selectedOption[0].maxWeight) {
      weightControl.setValue('');
      weightControl.setValidators([Validators.required, Validators.min(1), Validators.max(selectedOption[0].maxWeight)])
    }

    this._ref.detectChanges();
  }
  getFormGroup(name: string) {
    return this.group.get(name) as FormGroup;
  }





  myFilter: DateFilterFn<Date | null> = (date: Date | null) => {
    if (!date) {
      date = new Date();
    }

    return this._date.validateStartDate(date, this.group.get('selectedPlan')?.value!, this.group.get('selectedTab')?.value!)
  }

  ngAfterViewInit(): void {
    this.getFormGroup('address_preselect').valueChanges.pipe(
      debounceTime(200)
    ).subscribe(() => {
      if (this.getFormGroup('address_preselect').valid) {
        this.getDistance();
        if ((this.group.get('crates')!.get('crates') as FormArray).length > 0 && this.group.get('crates')?.get('crates')?.valid) {
          this.getPrices();
          this.isLoading = true;
        }
      }
    });

    this.getFormGroup('crates').valueChanges.pipe(
      debounceTime(200)
    ).subscribe(() => {
      if (this.getFormGroup('address_preselect').valid) {
        this.getDistance();
        if ((this.group.get('crates')!.get('crates') as FormArray).length > 0 && this.group.get('crates')?.get('crates')?.valid) {
          this.getPrices();
        }
      }
    });
    this.getEndDateRangeForPlan2();
    this.getDatesForPlan3();
  }

  private calculateTotal() {
    this.package_data = {
      packages: 0,
      loading_meter: 0,
      total_weight: 0
    };

    (this.group.get('crates')?.get('crates') as FormArray).controls.forEach(controls => {
      if (controls) {
        const packages = parseInt(controls.get('quantity')!.value, 10),
          loading_meter = ((controls.get('length')!.value / 100) * (controls.get('wide')!.value / 100) / 2.4) * Number(controls.value.quantity),
          weight = parseInt(controls.get('weight')!.value, 10) * Number(controls.value.quantity);
        this.package_data.packages += isNaN(packages) ? 0 : packages;
        this.package_data.loading_meter += isNaN(loading_meter) ? 0 : loading_meter;
        this.package_data.total_weight += isNaN(weight) ? 0 : weight;
      }
    });

    if ((this.package_data.total_weight > 24000 || this.package_data.loading_meter > 13.6) && !this.individual) {
      setTimeout(() => {
        this.showToMuchAlert();
      }, 100)
    }

    this.group.get('crates')!.get('loading_meter')!.setValue(this.package_data.loading_meter)
    this.group.get('crates')!.get('weight')!.setValue(this.package_data.total_weight);
  }


  showToMuchAlert() {
    if (this.individual) return;
    this.dialog.open(ToMuchLdmOrToHeavyModalComponent,
      { width: '400px' });
  }

  public addCrateGroup() {
    const crate = this.group.get('crates')?.get('crates') as FormArray;
    if (!crate) {
      console.error('FormArray crates wurde nicht gefunden oder ist null');
    } else {
      setTimeout(() => {
        crate.push(this.createNewCrateFormGroup(this.individual));
      }, 100)

    }
  }

  async autofillCity(field: string) {
    const city = await this._address.loadCityFromProfile();
    this.getFormGroup('address_preselect').get(field)?.setValue(city);
  }

  book() {
    this.isLoading = true;
    setTimeout(() => {
      this.nav.navigateRoot('/dashboard/order/direct');
    }, 200)


  }
  getCityName(city: any) {
    if (!city) {
      return '';
    }
    return `${city.zipcode} ${city.city}, ${city.country_code}`;
  }




  checkIfCityInGermany($event: any) {
    if (String($event.option.value.country_code).toUpperCase() != 'DE' && !this.allowedCountries.includes($event.option.value.country_code)) {
      this.dialog.open(CountryNotAllowedComponent, { width: '400px' });
      this.getFormGroup('address_preselect').get('from_address')?.setValue(null);
      this.getFormGroup('address_preselect').get('to_address')?.setValue(null);
    } else {
      if (this.getFormGroup('address_preselect').get('to_address')?.value) {
        Preferences.set({ key: 'to_address', value: JSON.stringify(this.getFormGroup('address_preselect').get('to_address')?.value) });
      }
      if (this.getFormGroup('address_preselect').get('from_address')?.value) {
        Preferences.set({ key: 'from_address', value: JSON.stringify(this.getFormGroup('address_preselect').get('from_address')?.value) });
      }
    }
  }

  ngOnInit() {
    this.getFormGroup('address_preselect').get('from_address')?.valueChanges.pipe(
      debounceTime(600)
    ).subscribe(search => this.updateSearch(search));
    this.getFormGroup('address_preselect').get('to_address')?.valueChanges.pipe(
      debounceTime(600)
    ).subscribe(search => this.updateSearch(search));
    this.loadAllowedCountries();

    const crate = this.group.get('crates')?.get('crates') as FormArray;

    this.group.get('crates')?.get('crates')?.valueChanges.pipe(
      debounceTime(500)
    ).subscribe(() => {
      this.calculateTotal();
    });

    if (crate.length == 0) {
      this.addCrateGroup();
    }

    const addressData = localStorage.getItem('formData');
    if (addressData) {
      const data = JSON.parse(addressData);
      this.getFormGroup('address_preselect').get('from_address')!.setValue(data.address_preselect.from_address);
      this.getFormGroup('address_preselect').get('to_address')?.setValue(data.address_preselect.to_address);
      //  localStorage.removeItem('formData');
    }

    const group = this.getFormGroup('loading_kind')
    group.markAllAsTouched()
  }

  formatPrice(price: number | string): string {
    if (typeof price === 'string') {
      price = parseFloat(price.replace(/\./g, '').replace(',', '.'));
    }
    
    const formatted = price.toLocaleString('de-DE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    
    return formatted;
  }
  


  async updateSearch(searchString: string | null) {
    if (searchString && searchString.length > 1) {
      const results = await this._address.findResults(searchString);
      this.cities = results;
    } else {
      this.cities = [];
    }
  }


  async loadAllowedCountries() {
    try {
      const result: any = await this._store.collection<PriceSettings>('settings').doc('price').get().toPromise();
      this.allowedCountries = Object.keys(result.data()!.country_gesperrt).filter(key => !result.data()?.country_gesperrt[key]);
    } catch (error) { }
  }
  async getLang() {
    this.currentLang = (await Preferences.get({ key: 'language' })).value ?? 'de';
  }

  async setLang(lang: string) {
    this.currentLang = lang;
    this._translate.use(lang);
    this._translate.reloadLang(lang);
    Preferences.set({ key: 'language', value: lang });
  }

  selectPlan(plan: number) {
    if (plan !== this.selectedPlan) {
      this.selectedPlan = plan;
      this.shippingPriceWithOutDC = this.formatPrice(this.planPriceData[Number(plan)]);
      this.getFormGroup('additional_information').reset();
      this.start_date = '';
      this.scrollToBottom('timeFrame');
    }
  }

  findFirstAvailableDate(selectedPlan: any): Date | null {
    let date = dayjs().startOf('day');

    if (selectedPlan == 2) {
      date = dayjs().add(1, 'd');

      while (this._date.isPubicHoliday(date.toDate()) || date.day() == 0 || date.day() == 6) {
        date = date.add(1, 'd');
      }
      return date.toDate();
    }


    while (!this._date.validateStartDate(date.toDate(), selectedPlan, 0)) {
      date = date.add(1, 'day');
    }
    return date.toDate();
  }
  getDatesForPlan3() {
    const start = this.findFirstAvailableDate(2)!;

    this.plan3Range = this._date.getRangeForPlan3(start)
  }

  getPrices() {
    this.selectedPlan = null;
    this.pricesLoading = true;
    const data: any = this.group.getRawValue();
    data['selectedTab'] = 0;
    this._http.post(`${environment.api_url}/direct/options/price`, data).subscribe((result: any) => {
      this.planPriceData = result.map((option: any) =>
        this.formatPrice(parseFloat(option.price))
      );
      this.pricesLoading = false;
      this.scrollToBottom('tarifArea')
    })
  }

  getEndDateRangeForPlan2() {
    this._date.distance.subscribe(distance => {
      const start = this._date.getStartDateForPlan2(this.findFirstAvailableDate(1)!, distance);
      this.plan2StartDate = this._date.getEndDateRangeForPlan2(this.findFirstAvailableDate(1)!, distance);
      this.plan2EndDateRange = this.formatDate(start)
    })
  }

  formatDate(start: Date): string {
    return `${dayjs(start).format('DD.MM.YYYY')} `;
  }
  getTimeslots() {
    if (this.getFormGroup('additional_information').get('end_date')?.value) {
      const endDateString: any = this.getFormGroup('additional_information').get('end_date')?.value;
      const endDate = new Date(endDateString);
      return this._date.getPickupSlots(endDate, 0, this.group.get('selectedTab')?.value!, true)
    } else {
      return [];
    }
  }

  getDistance() {
    const start: any = this.getFormGroup('address_preselect').get('from_address')?.value;
    const destination: any = this.getFormGroup('address_preselect').get('to_address')?.value;
    this._http.put<any>(`${environment.api_url}/distance`, {
      start,
      destination

    }).subscribe(result => {
      this.distance = result.distance;
      this._date.distance.next(this.distance)
    })
  }
  updateEndDate($event: any) {
    if (this.group.get('selectedTab')?.value !== 2) {
      if (this.selectedPlan == 0 || this.selectedPlan == 3) {
        this.start_date = this._date.getRangeForPlan1(new Date($event.target.value));
        this.getFormGroup('additional_information').get('pickup_start_date')?.setValue(this._date.getStartDateForPlan1(new Date($event.target.value)))
        this.getFormGroup('additional_information').get('deliver_start_date')?.setValue(this._date.getStartDateForPlan1(new Date($event.target.value)))
        this.getFormGroup('additional_information').get('pickup_end_date')?.setValue(this._date.getEndDateForPlan1Date(new Date($event.target.value)))
        this.getFormGroup('additional_information').get('deliver_end_date')?.setValue(this._date.getEndDateForPlan1Date(new Date($event.target.value)))
        this.getFormGroup('additional_information').get('end_date')?.setValue(this._date.getRangeForPlan1(new Date($event.target.value)));
      }
      if (this.selectedPlan == 1) {
        this.start_date = this._date.getEndDateRangeForPlan2(new Date($event.target.value), this.distance);
        this.getFormGroup('additional_information').get('pickup_start_date')?.setValue(this._date.getStartDateForPlan2(new Date($event.target.value), this.distance))
        this.getFormGroup('additional_information').get('deliver_start_date')?.setValue(this._date.getStartDateForPlan2(new Date($event.target.value), this.distance))
        this.getFormGroup('additional_information').get('pickup_end_date')?.setValue(this._date.getEndDateForPlan2Date(new Date($event.target.value), this.distance))
        this.getFormGroup('additional_information').get('deliver_end_date')?.setValue(this._date.getEndDateForPlan2Date(new Date($event.target.value), this.distance))
        this.getFormGroup('additional_information').get('end_date')?.setValue(this._date.getEndDateRangeForPlan2(new Date($event.target.value), this.distance));
      }
      if (this.selectedPlan == 2) {
        this.getFormGroup('additional_information').get('pickup_start_date')?.setValue(this._date.getStartDateForPlan3(new Date($event.target.value),))
        this.getFormGroup('additional_information').get('deliver_start_date')?.setValue(this._date.getEndDateForPlan3Date(new Date($event.target.value),))
        this.getFormGroup('additional_information').get('pickup_end_date')?.setValue(this._date.getStartDateForPlan3(new Date($event.target.value)))
        this.getFormGroup('additional_information').get('deliver_end_date')?.setValue(this._date.getEndDateForPlan3Date(new Date($event.target.value)))
        this.start_date = dayjs(new Date($event.target.value)).format('DD.MM.YYYY');
        this.getFormGroup('additional_information').get('end_date')?.setValue(dayjs(this._date.getEndDateForPlan3Date(new Date($event.target.value))).format('DD.MM.YYYY'))
      }
    } else {
      const startDate = this._date.getStartDateForPlan2(new Date($event.target.value), this.distance);
      this.getFormGroup('additional_information').get('pickup_start_date')?.setValue(startDate)
      this.getFormGroup('additional_information').get('deliver_start_date')?.setValue(startDate)
      const endDate = this._date.getEndDateForPlan2Date(new Date($event.target.value), this.distance);
      this.getFormGroup('additional_information').get('pickup_end_date')?.setValue(endDate)
      this.getFormGroup('additional_information').get('deliver_end_date')?.setValue(endDate)
      this.start_date = this._date.getEndDateRangeForPlan2(new Date($event.target.value), this.distance);
      this.getFormGroup('additional_information').get('end_date')?.setValue(this._date.getEndDateRangeForPlan2(new Date($event.target.value), this.distance));
    }
  }

  getLoadingMetreForCheckout() {
    return `${this.getFormGroup('crates').get('loading_meter')?.value?.toFixed(2) ?? 0} ldm`
  }

  getTotalWeightForCheckout() {
    return `${this.getFormGroup('crates').get('weight')?.value ?? 0} kg`
  }
  getAddress(address: string): any {
    const value: any = this.group.value;
    if (!value?.address_preselect[address]?.country_code) {
      return null;
    } else {
      const set = value.address_preselect[address];
      return `${set.city}, ${set.state}, ${set.country_code}`;
    }

  }

  getMWST() {
    if (this.shippingPriceWithOutDC) {
      const price = parseFloat(this.shippingPriceWithOutDC.replace('.', '').replace(',', '.'));
      return this.formatPrice(price * 0.19);
    }
    return '';
  }
  getTotal() {
    if (this.shippingPriceWithOutDC) {
      const price = parseFloat(this.shippingPriceWithOutDC.replace('.', '').replace(',', '.'));
      this.total = price;
      return this.formatPrice(price * 1.19);
    }
    return '';
  }


  async scrollToBottom(area: string): Promise<void> {
    await new Promise(resolve => setTimeout(resolve, 200));
    const targetElement = document.getElementById(area);
    targetElement!.scrollIntoView({ behavior: "smooth" });
  }

  async storeAsSession() {
    if (this.group.invalid) {
      this.group.markAllAsTouched();
      (await this._toast.create({
        message: await this._translate.get('Bitte fülle alle Felder aus um fortzufahren').toPromise()
      })).present();
      return;

    }
    const loadingMessage = await this._translate.get('loading_controller_text').toPromise();
    const loader = await this._loading.create({
      message: loadingMessage
    });

    await loader.present();
    this._http.post<any>(`${environment.api_url}/session`, this.group?.getRawValue()).subscribe((result: any) => {
      loader.dismiss();
      Preferences.set({ key: 'session', value: result.sessionId });
      this.nav.navigateRoot('/dashboard/order?sessionId=' + result.sessionId);

    });
  }

  checkIfTooHeavy() {

    let isTooHeavy = false;
    this.getControls().forEach((control: any) => {
      if (control.value.weight > 1000) {
        isTooHeavy = true;
      }
    });
    return isTooHeavy;
  }
}
