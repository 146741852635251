import { trigger, state, style, transition, animate } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LoadingController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { TableDetailBottomsheetComponent } from 'src/app/components/table-detail-bottomsheet/table-detail-bottomsheet.component';
import { SupplierAuctionAnonymousDetailComponent } from '../supplier-auction-anonymous-detail/supplier-auction-anonymous-detail.component';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';

interface ColumnDefinition {
  id: string;
  name: string;
  data: string[];
  minWidth?: string | 'auto' | undefined | null;
  parser?: string | 'date' | 'price' | 'datetime' | 'countryflag' | 'countdown' | 'weight' | 'feedback' | undefined | null;
  suffix?: string | undefined | null;
  prefix?: string | undefined | null;
  translate?: boolean | undefined | null;
  feedback?: Function | undefined | null;
  textCenter?: boolean | undefined | null;
}

@Component({
  selector: 'app-table-filtering',
  templateUrl: './table-filtering.component.html',
  styleUrls: ['./table-filtering.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('in', style({
        overflow: 'hidden',
        height: '*',
        width: '300px'
      })),
      state('out', style({
        opacity: '0',
        overflow: 'hidden',
        height: '0px',
        width: '0px'
      })),
      transition('in => out', animate('240ms ease-in-out')),
      transition('out => in', animate('240ms ease-in-out'))
    ])
  ]
})
export class TableFilteringComponent implements OnInit {

  @Input()
  isSupplier: boolean = false;

  @Input()
  length: number = 0;

  @Input()
  index: number = 0;

  @Input()
  size: number = 0;
  @Input()
  data: any[] = [];

  @Input()
  columnDefinition: ColumnDefinition[] = [];

  @ContentChild(TemplateRef) expandedDetail?: TemplateRef<any>;

  @Output()
  selectionChanged: EventEmitter<any> = new EventEmitter();

  @Output()
  paginatorChanged: EventEmitter<any> = new EventEmitter();

  @Output()
  reloadData: EventEmitter<string> = new EventEmitter();

  @ViewChild(MatPaginator)
  matPaginator!: MatPaginator;

  @ViewChild(MatSort)
  matSort!: MatSort;

  JSON = JSON;

  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  selectedElements: number[] = [];
  selection = new SelectionModel<any>(true, []);
  expandedElement: any = null;

  columnFilter: any = {};
  searchValues: any = {};

  displayedColumns: string[] = [];

  constructor(private _loading: LoadingController,
    private _bottomSheet: MatBottomSheet,
    private _modal: ModalController,
    private _translate: TranslateService,
    private router: Router) { }

  ngOnInit() {
    this.prepareData();

    this.selection.changed.subscribe(result => {
      this.selectionChanged.emit(result.source.selected);
    });
  }

  filterChanged(id: string) {
    this.getValues(id);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.matSort;
    this.dataSource.filterPredicate = (data: any, filter) => {
      if (!filter) return true;
      const dataFilter = JSON.parse(filter);
      let status = true;
      Object.keys(dataFilter).forEach(key => {
        if (!dataFilter[key].includes(data[key])) status = false;
      });
      return status;
    }
  }

  handlePageEvent($event: PageEvent) {
    this.paginatorChanged.emit($event);
  }

  getValueForTooltip(value: string): any {
    value = value.replace('<br/>', ' ')
    return value.replace('<br/>', ' ')
  }

  prepareData() {
    this.dataSource.data = this.data.map(localData => {
      const obj: any = {};

      this.columnDefinition.forEach(definition => {
        let columnData = '';

        definition.data.forEach(key => {
          if (key == '<br/>') {
            columnData += "<br/>";
          } else {
            columnData += (this.outputValue(localData, key) ?? '') + ' ';
          }
        });
        columnData = columnData.replace(/((\s*\S+)*)\s*/, "$1");

        if (definition.parser == 'date') {
          columnData = dayjs(columnData).format('DD.MM.YYYY');
        } else if (definition.parser == 'datetime') {
          columnData = dayjs(columnData).format('DD.MM.YYYY HH:mm');
        } else if (definition.parser == 'price') {

          if (isNaN(Number(columnData))) {
            columnData = '-'
          } else {
            columnData = Number(columnData).toFixed(2).toLocaleString()
          }

        } else if (definition.parser == 'countdown') {
          columnData = this.getDifference(columnData);
        } else if (definition.parser == 'weight') {
          columnData = Number(columnData).toLocaleString('de-DE');
        } else if (definition.parser == 'feedback') {
          if (!localData.feedback) {
            columnData = '-'
          } else {
            switch (localData?.feedback?.rating) {
              case 1:
                columnData = '../../../assets/icon/angry.png';
                break;
              case 2:
                columnData = '../../../assets/icon/sad.png';
                break;
              case 3:
                columnData = '../../../assets/icon/neutral.png';
                break;
              case 4:
                columnData = '../../../assets/icon/happy.png';
                break;
              default:
                columnData = '../../../assets/icon/very_happy.png';
                break;
            }
          }
        }
        if (!columnData) {
          columnData = "-"
        }
        obj[definition.id] = columnData ?? '';
      });
      obj.rawData = localData;

      return obj;
    });
    this.displayedColumns = ['selection'].concat(this.columnDefinition.map(data => data.id));
  }

  outputValue(obj: any, path: string): any {
    const index = path.indexOf('.')
    if (index === -1) {
      let keyValue: any = path;
      if (!isNaN(keyValue)) keyValue = parseInt(keyValue, 10);
      return obj?.[keyValue]
    }
    let keyValue: any = path.slice(0, index);
    if (!isNaN(keyValue)) keyValue = parseInt(keyValue, 10);

    return this.outputValue(obj?.[keyValue], path.slice(index + 1))
  }

  /**
   * Methods used for column selection
   */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  getValues(id: string) {
    if (this.searchValues[id]) {
      const data = [...new Set(this.dataSource.data.map(elem => elem[id]))].map(
        elem => elem.toLowerCase()
      );
      return data.filter(elem => elem.indexOf(this.searchValues[id]) !== -1);
    }

    return [...new Set(this.dataSource.data.map(elem => elem[id]))]
      .filter(value => value !== null && value !== undefined && value !== "");
  }

  clearFilter(id: string) {
    delete this.columnFilter[id];
    delete this.searchValues[id];
    this.updateFilter();
    this.expandedElement = null;
  }

  toggleFilter(id: string, value: string, event: any) {
    if (event) {
      if (!this.columnFilter[id]?.includes(value)) {
        if (this.columnFilter[id]?.length > 0) {
          this.columnFilter[id].push(value);
        } else {
          this.columnFilter[id] = [value];
        }
      }
    } else {
      if (this.columnFilter[id]?.includes(value)) {
        if (this.columnFilter[id]?.length == 1) {
          delete this.columnFilter[id];
        } else {
          this.columnFilter[id].splice(this.columnFilter[id].indexOf(value), 1);
        }
      }
    }
    this.updateFilter();
  }

  toggleAllValues(id: string, event: any) {
    if (event) {
      this.columnFilter[id] = [...new Set((this.columnFilter[id] ?? []).concat(this.getValues(id)))]
    } else {
      this.getValues(id).forEach((elem: string) => {
        const index = this.columnFilter[id].indexOf(elem);
        if (index !== -1) {
          this.columnFilter[id].splice(index, 1);
        }
        if (this.columnFilter[id]?.length == 0) {
          delete this.columnFilter[id];
        }
      });
    }
    this.updateFilter();
  }

  private updateFilter() {
    if (Object.keys(this.columnFilter).length > 0) {
      this.dataSource.filter = JSON.stringify(this.columnFilter);
    } else {
      this.dataSource.filter = '';
    }
    this.expandedElement = null;
  }

  async downloadFile(data: any) {
    const loader = await this._loading.create({
      message: 'Exporting data ...'
    });
    await loader.present();
    const parsedData = JSON.parse(JSON.stringify(data));
    delete parsedData.rawData;

    const replacer = (key: any, value: any) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = await Promise.all(
      this.columnDefinition.map(def => this._translate.get(def.name).toPromise())
    );

    const csv = parsedData.map((row: any) =>
      Object.keys(parsedData[0])
        .map((fieldName: string) => JSON.stringify(row[fieldName], replacer))
        .join(',')
    );
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');

    const a = document.createElement('a');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    await loader.dismiss();

    a.href = url;
    a.download = 'Data Export.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
  async openBottomSheet(data: any) {
    if (this.isSupplier) {
      const ref = this._bottomSheet.open(SupplierAuctionAnonymousDetailComponent, {
        data: data,
      });
      ref.afterDismissed().subscribe(result => {
        if (result?.offerChanged) {
          this.reloadData.emit();
        }
      })

    } else {
      if (data.status == 'status_direct_draft') {
        this.router.navigate([`/dashboard/order/direct/${data._id}`]);
      } else if (data.status == 'status_auction_draft') {
        this.router.navigate([`/dashboard/order/auction/${data._id}`]);
      } else if (data.status == 'status_anonymous_draft') {
        this.router.navigate([`/dashboard/order/anonymous/${data._id}`]);
      } else {
        const modal = await this._modal.create({
          component: TableDetailBottomsheetComponent,
          componentProps: { data },
          initialBreakpoint: 0.7,
          breakpoints: [0.7],
          cssClass: 'table-filtering-bottom',
          id: 'table-filtering',
          mode: 'ios'
        });
        await modal.present();
      }
    }
  }
  getDifference(columnData: string): string {
    const today = dayjs();
    const auctionDate = dayjs(columnData);
    const auctionDuration = auctionDate.diff(today);
    const diffMs = auctionDuration // milliseconds between now & Christmas
    const diffDays = Math.floor(diffMs / 86400000); // days
    const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    if (diffDays <= 0 && diffHrs <= 0 && diffMins <= 0) {
      return 'abgelaufen'
    }
    return diffDays + " Tage, " + diffHrs + " Stunden, " + diffMins + " Minuten";
  }
}
