<form [formGroup]="group">
  <div class="row mt-4 pt-2">
    <div class="col-lg-6 col-12">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label translate>loading_meter</mat-label>
        <input formControlName="loading_meter" matInput type="number" min="0.1" />
        <span matTextSuffix [ngStyle]="{'color': isIframe ? '#bbb' : 'inherit'}">m</span>
        <mat-error style="margin-top: 3px;" *ngIf="!individual" translate>
          <span translate>metre_to_min</span>. <span translate>metre_to_much</span>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-lg-6 col-12">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label translate>total_weight</mat-label>
        <input formControlName="weight" matInput mask="separator" thousandSeparator="." />
        <span matTextSuffix [ngStyle]="{'color': isIframe ? '#bbb' : 'inherit'}">kg</span>
        <mat-error style="margin-top: 3px;" *ngIf="!individual" translate>weight_to_much</mat-error>
      </mat-form-field>
    </div>
  </div>

</form>